export default [
  {
    order: 1,
    name: "Residential",
    value: "Multifamily",
    abbreviation: "MF",
    frontEndKey: "multifamily",
    backEndKey: "multifamily",
    description: "Apartments, condos, single-family rentals",
    measure: "Units",
    unselectedColor: "bg-green-200",
    unselectedFromColor: "from-green-200",
    unselectedViaColor: "via-green-200",
    unselectedToColor: "to-green-200",
    selectedColor: "bg-green-300",
    selectedFromColor: "from-green-300",
    selectedViaColor: "via-green-300",
    selectedToColor: "to-green-300",
  },
  {
    order: 2,
    name: "Office",
    value: "Office",
    abbreviation: "Off",
    frontEndKey: "office",
    backEndKey: "office",
    measure: "SF",
    unselectedColor: "bg-blue-200",
    unselectedFromColor: "from-blue-200",
    unselectedViaColor: "via-blue-200",
    unselectedToColor: "to-blue-200",
    selectedColor: "bg-blue-300",
    selectedFromColor: "from-blue-300",
    selectedViaColor: "via-blue-300",
    selectedToColor: "to-blue-300",
  },
  {
    order: 3,
    name: "Retail",
    value: "Retail",
    abbreviation: "Ret",
    frontEndKey: "retail",
    backEndKey: "retail",
    measure: "SF",
    unselectedColor: "bg-pink-200",
    unselectedFromColor: "from-pink-200",
    unselectedViaColor: "via-pink-200",
    unselectedToColor: "to-pink-200",
    selectedColor: "bg-pink-300",
    selectedFromColor: "from-pink-300",
    selectedViaColor: "via-pink-300",
    selectedToColor: "to-pink-300",
  },
  {
    order: 4,
    name: "Industrial",
    value: "Industrial",
    abbreviation: "Ind",
    frontEndKey: "industrial",
    backEndKey: "industrial",
    measure: "SF",
    unselectedColor: "bg-yellow-200",
    unselectedFromColor: "from-yellow-200",
    unselectedViaColor: "via-yellow-200",
    unselectedToColor: "to-yellow-200",
    selectedColor: "bg-yellow-300",
    selectedFromColor: "from-yellow-300",
    selectedViaColor: "via-yellow-300",
    selectedToColor: "to-yellow-300",
  },
  {
    order: 5,
    name: "Hotel",
    value: "Hotel",
    abbreviation: "Hot",
    frontEndKey: "hotel",
    backEndKey: "hotel",
    measure: "Keys",
    unselectedColor: "bg-violet-200",
    unselectedFromColor: "from-violet-200",
    unselectedViaColor: "via-violet-200",
    unselectedToColor: "to-violet-200",
    selectedColor: "bg-violet-300",
    selectedFromColor: "from-violet-300",
    selectedViaColor: "via-violet-300",
    selectedToColor: "to-violet-300",
  },
  {
    order: 6,
    name: "Casino",
    value: "Casino",
    abbreviation: "Cas",
    frontEndKey: "casino",
    backEndKey: "casino",
    measure: "SF",
    unselectedColor: "bg-violet-200",
    unselectedFromColor: "from-violet-200",
    unselectedViaColor: "via-violet-200",
    unselectedToColor: "to-violet-200",
    selectedColor: "bg-violet-300",
    selectedFromColor: "from-violet-300",
    selectedViaColor: "via-violet-300",
    selectedToColor: "to-violet-300",
  },
  {
    order: 7,
    name: "Research & Development",
    value: "R&D",
    abbreviation: "R&D",
    frontEndKey: "researchDevelopment",
    backEndKey: "research_development",
    measure: "SF",
    unselectedColor: "bg-teal-200",
    unselectedFromColor: "from-teal-200",
    unselectedViaColor: "via-teal-200",
    unselectedToColor: "to-teal-200",
    selectedColor: "bg-teal-300",
    selectedFromColor: "from-teal-300",
    selectedViaColor: "via-teal-300",
    selectedToColor: "to-teal-300",
  },
  {
    order: 8,
    name: "Healthcare",
    value: "Healthcare",
    abbreviation: "Hlth",
    frontEndKey: "healthCare",
    backEndKey: "health_care",
    description: "Medical office, hospitals.",
    measure: "SF",
    unselectedColor: "bg-blue-200",
    unselectedFromColor: "from-blue-200",
    unselectedViaColor: "via-blue-200",
    unselectedToColor: "to-blue-200",
    selectedColor: "bg-blue-300",
    selectedFromColor: "from-blue-300",
    selectedViaColor: "via-blue-300",
    selectedToColor: "to-blue-300",
  },
  {
    order: 9,
    name: "Senior Housing",
    value: "Senior Housing",
    abbreviation: "Snr",
    frontEndKey: "seniorHousing",
    backEndKey: "senior_housing",
    measure: "Units",
    unselectedColor: "bg-lime-200",
    unselectedFromColor: "from-lime-200",
    unselectedViaColor: "via-lime-200",
    unselectedToColor: "to-lime-200",
    selectedColor: "bg-lime-300",
    selectedFromColor: "from-lime-300",
    selectedViaColor: "via-lime-300",
    selectedToColor: "to-lime-300",
  },
  {
    order: 10,
    name: "Data Center",
    value: "Data Center",
    abbreviation: "DCtr",
    frontEndKey: "dataCenter",
    backEndKey: "data_center",
    measure: "MW",
    unselectedColor: "bg-violet-200",
    unselectedFromColor: "from-violet-200",
    unselectedViaColor: "via-violet-200",
    unselectedToColor: "to-violet-200",
    selectedColor: "bg-violet-300",
    selectedFromColor: "from-violet-300",
    selectedViaColor: "via-violet-300",
    selectedToColor: "to-violet-300",
  },
  {
    order: 11,
    name: "Student Housing",
    value: "Student Housing",
    abbreviation: "Stu",
    frontEndKey: "studentHousing",
    backEndKey: "student_housing",
    measure: "Units",
    unselectedColor: "bg-lime-200",
    unselectedFromColor: "from-lime-200",
    unselectedViaColor: "via-lime-200",
    unselectedToColor: "to-lime-200",
    selectedColor: "bg-lime-300",
    selectedFromColor: "from-lime-300",
    selectedViaColor: "via-lime-300",
    selectedToColor: "to-lime-300",
  },
  {
    order: 12,
    name: "Self-Storage",
    value: "Self-Storage",
    abbreviation: "S-Stor",
    frontEndKey: "selfStorage",
    backEndKey: "self_storage",
    measure: "Units",
    unselectedColor: "bg-orange-200",
    unselectedFromColor: "from-orange-200",
    unselectedViaColor: "via-orange-200",
    unselectedToColor: "to-orange-200",
    selectedColor: "bg-orange-300",
    selectedFromColor: "from-orange-300",
    selectedViaColor: "via-orange-300",
    selectedToColor: "to-orange-300",
  },
];
